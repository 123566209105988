<app-system-message></app-system-message>
<!-- The list of routes for today -->
<div [ngClass]="theme.getThemeClass()" class="route-list-main" *ngIf="this.displayRoutesList">
  	<h1 class="page-header">{{'routeList' | translate}}</h1>
  <section class="route-tiles-container">
		<ng-container *ngFor="let route of routesArray; let i = index">
			<article class="route-tile" [id]="'route_' + i"
				[ngClass]="{'disabled-route': route.end_date != null}" 
				(click)="clickOnRoute(route.route_id)">
					<h3>{{'route' | translate}} {{ i }}</h3>
            <p>{{route.route_id}}</p>
			</article>
		</ng-container>
	</section>
</div>
<!---------------------------------->

<!-- Stops and the route map-->
<div class="route-main" *ngIf="this.displayStopsAndMap">
<!--<h1 class="page-header">Selected route</h1>-->
	<div class="main-container container mt-5">
		<!-- Stops -->
		<section [ngClass]="theme.getThemeClass()" class="stops-container d-flex align-items-center">
        <label class="form-label">Stop List:</label>
        <ng-select class="form-select flex-grow-1 me-2" [(ngModel)]="this.selectedStopIndex" (change)="this.clickOnStop(this.selectedStopIndex!)">
          <ng-option class="stop-label" *ngFor="let stop of this.routeService.stopsArray" [value]="stop.index">Stop {{stop.index}}</ng-option>
        </ng-select>
        <button class="btn btn-dropdown" (click)="this.pickupBinButton()" id="pickupBinButton" disabled>{{'pickup' | translate}}</button>
    </section>
		<!-- Map -->
		<section id="map-container" class="map-container"></section>
	</div>

  <!-- Buttons -->
  <div style="display:inline">
    <button class="btn btn-dropdown" (click)="stopVoice()">
      <i id="speaker-icon" class="bi bi-volume-up" (click)="volumeButton()"></i>
    </button>
    <button class="btn btn-dropdown" (click)="focusOnUserPosition()">User Position</button>
    <button class="btn btn-dropdown" (click)="returnToRoutes()">Return</button>
    <!-- <button (click)="openCantPickupModal()">Modal</button> -->
  </div>
</div>
<!---------------------------->

<!-- Bin bick up window 1 (initial pickup modal)-->
<div>
  <article [ngClass]="theme.getThemeClass()" class="pickup-modal" id="pickup-modal">
    <h2>Picking up bin at</h2>
    <h4>{{ this.selectedStopDetails?.bin_address}}</h4>
    <div class="modal-buttons">
      <button (click)="acceptButton()">Accept</button>
      <button (click)="closePickupModal()">Cancel</button>
      <button (click)="cantButton()">Cant</button>
    </div>
  </article>
</div>
<!----------------------------------------------->

<!-- Bin bick up window 2 ('cant' pickup modal)-->
<div>
  <article [ngClass]="theme.getThemeClass()" class="pickup-modal" id="cant-pickup-modal">
    <h2 class="cantPickupTitle">{{ 'cantPickupModalTitle' | translate }}</h2>
    <h4>{{ 'pickupErrorReason' | translate }}</h4>
    <select [(ngModel)]="this.selectedError" class="select-error">
			<option *ngFor="let error of RouteStopErrors" [value]="error.value">{{ error.label | translate }}</option>
		</select>
    <h4>{{ 'comment' | translate }}:</h4>
		<textarea [(ngModel)]="this.cantPickupTextDetail" class="textarea-error"></textarea>
    <div class="modal-buttons">
      <button (click)="acceptCantPickupButton()">Accept</button>
      <button (click)="closeCantPickupButton()">Cancel</button>
    </div>
  </article>
 </div>
<!----------------------------------------------->

