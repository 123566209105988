import { Component, Inject, forwardRef, OnInit } from '@angular/core';
import { ThemeService } from '../service/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../local-storage.service';
import { SystemMessageService } from '../service/system-message.service';
import { CognitoService } from '../service/cognito.service';
import { TruckService } from '../service/truck.service';
import { RouteService, Stop } from '../service/route.service';
import { ValidationService } from '../service/validation.service';
import * as L from 'leaflet';
import 'leaflet-routing-machine';
import { RouteStopErrors } from '../constants/route-stop-errors';
import { firstValueFrom } from 'rxjs';
import { WakeLockService } from '../service/wake-lock.service';
import { GeoLocation } from 'aws-sdk/clients/route53';

@Component({
  selector: 'app-driver-routes',
  templateUrl: './driver-routes.component.html',
  styleUrls: ['./driver-routes.component.css']
})

export class DriverRoutesComponent implements OnInit{

  constructor(
    @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    public theme: ThemeService,
    public localStorageService: LocalStorageService,
    public systemMessageService: SystemMessageService,
    public cognitoService: CognitoService,
    public truckService: TruckService,
    public routeService: RouteService,
    public validationService: ValidationService,
    public wakeLockService: WakeLockService
  ){ this.setPageLanguage(translate); }

  public languageStatus: string = this.localStorageService.getItem('language');
  public displayRoutesList: boolean = true;
  public displayStopsAndMap: boolean = false;

  public routesArray: any = [];
  //public stopsArray : any = [];
  public mapCenterDefault: any;

  private route_id: string = '';
  private driver_id: string = '';
  private truck_id: string = '';

  private map !: L.Map;
  private routingControl: any;
  private userMarker!: L.Marker; // User marker
  public selectedStopDetails?: Stop
  public selectedError: string = '';
  public cantPickupTextDetail: string = '';
  private stopMarkers: L.Marker[] = []; // Array of our stop markers
  private instructions: any;
  private lastCurrentInstructionRead: string = ''; // Hold the last 'current instruction' used by the TTS
  private lastNextInstructionRead: string = ''; // Hold the last 'next instruction' used by the TTS
  private voices: SpeechSynthesisVoice[] = [];
  private langToUseWithVoice: string = '';
  private proximityThreshold: number = 40; // Distance in meters to be around a bin to accept it
  private isSoundMuted: boolean = false;
  private pickupModal: any;
  private cantPickupModal: any;
  public RouteStopErrors = RouteStopErrors;
  public selectedStopIndex: number | undefined = undefined;


  private setPageLanguage(translate: TranslateService) {
    if (this.languageStatus == null) {
      // Set the default language to French
      translate.use('fr');
      this.localStorageService.addItem('language', 'fr');
    } else {
      // Set the default language to the user's selected language
      translate.use(this.languageStatus);
    }
  }

  async ngOnInit(){
    await this.cognitoService.getUser(); // TODO this is already called in the service, find a way to wait for it
    this.routeService.driver_id = this.cognitoService.userInfos.attributes['sub'];
    this.driver_id = this.routeService.driver_id;
    // Get truck associated with the driver
    this.truck_id = await this.truckService.getTruckIdByDriverId(this.driver_id);
    // Get the routes assiciated with our driver
    await this.routeService.getRoutesIdByTruckId(this.truck_id);
    this.routesArray = this.routeService.driverRoutesArray;
    this.pickupModal = document.getElementById('pickup-modal');
    this.cantPickupModal = document.getElementById('cant-pickup-modal');
    // Do we have routes?
    if(this.routesArray.length > 0){
      //console.log('ROUTES AVAILABLE');
    }else{
      alert('NO ROUTES AVAILABLE');
    }
    this.setLanguageToUse()
    this.loadVoices();
    window.speechSynthesis.onvoiceschanged = () => {
      this.loadVoices();
    };
    // TODO add loading screen on init
  }

  loadVoices() {
    this.voices = window.speechSynthesis.getVoices();
  }

  /** Initialize the map in the map-container HTML element, also position the user marker and all the stop markers.
   * Then we check every couples of seconds if we are close to a marker
  */
  private mapInit(){
    // Set the map on the HTML element
    this.map = L.map('map-container');
    // Set default view, necessary for initial map tiles loading. This is Muirwood Studio location.
    this.map.setView([45.650, -73.848], 18);
    //this.map.panTo(this.map.getCenter());
    // Do we have a map?
    if(this.map){
      // Get the map tiles and add it to the map
      L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 20,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      }).addTo(this.map);

      // Do we have geolocation?
      if(navigator.geolocation){
        // Add the stops markers to the map
        this.addStopMarkers();
        // Geolocation listeners handlers
        this.geolocationHandler();
        // Prevent mobiles screens from going to sleep
        this.wakeLockService.requestWakeLock();
      }else{
        console.log('Geolocation is not supported.');
        return;
      }
    }else{
      console.log('Map is not initialized');
      return;
    }
  }


  private geolocationHandler() {
    // Update the map when user location changes
    navigator.geolocation.watchPosition(
      // Success
      (position: GeolocationPosition) => {
        if(this.userMarker == null){
          // InitializeUserMarker
          this.initializeUserMarker(position);
        }

      },
      // Error
      (error: GeolocationPositionError) => {
        this.GeolocationPositionErrorHandling(error);
      },
      // Options
      {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 10000
      }
    );
  }

  private addStopMarkers() {
    // Remove markers if they exist
    if (this.stopMarkers.length > 0) {
      this.stopMarkers.forEach(marker => {
        this.map.removeLayer(marker);
      });
      console.log('Removed stop markers');
    }
    // Display all stops marker on the map
    this.routeService.stopsArray.forEach((stop, index) => {
      // Split the gps coord into numbers
      const [latitude, longitude] = stop.bin_gps.split(',').map(Number);

      /* // Creation of the custom garbage icon as the stop markers
      const customBinIcon = L.divIcon({
        className: 'custom-icon', // Add a custom class
        html: '<i id="marker_' + (index + 1) + '" class="bi bi-trash" style="font-size: 24px; color: red;"></i>',
        iconSize: [30, 30], // Size of the icon
        iconAnchor: [15, 30] // Anchor point of the icon
      }); */

      // Add marker with custom icon to its position
      const marker = L.marker([latitude, longitude])//, { icon: customBinIcon }).addTo(this.map)
      // Add a popup to be display when clicking on the marker
      const popup_message = stop.bin_address;
      // Bind popup text to the marker and offset it a little so it doesnt cover the icon
      marker.bindPopup(popup_message, {offset: L.point(-3,-15)});
      // Add the marker to the stopMarkers array
      this.stopMarkers.push(marker);
    });
  }

  /**
   * Initialize the user marker
   */
  private initializeUserMarker(position: GeolocationPosition) {
    // Get the user position using geolocation
    const userPosition: L.LatLngTuple = [position.coords.latitude, position.coords.longitude];
    // Add new user marker on the map
    this.userMarker = L.marker(userPosition)//.addTo(this.map);
  }

  /** Check if the user marker is close to a stop marker */
  private isInProximityAndInProgress(stopIndex: any): boolean {
    // Check if userMarker is defined
    if(!this.userMarker){
      console.log('User marker is not defined');
      return false;
    }
    // Get our user latitude and longitude
    const userLatLng = this.userMarker.getLatLng();
    // Get the selected stop
    const stop = this.routeService.stopsArray[stopIndex-1];
    // Format our gps coordinate into an array of number
    const latlng: number[] = stop.bin_gps.split(',').map(Number);
    // Make our array of gps coordinate into a LatLngTuple for distance comparairson
    const stopLatLng: L.LatLngTuple = [latlng[0],latlng[1]];
    // Set distance between the user and the stop
    const distance = userLatLng.distanceTo(stopLatLng);
    // Check if we are within range
    if(distance < this.proximityThreshold){
      // Check if the stop is in progress
      if(stop.status == 'I'){
        return true
      }
      else{
        alert('This stop has already been completed');
        return false;
      }
    }
    else{
      alert('You are not close enough to the bin, you need to be within ' + this.proximityThreshold + ' meters.');
      return false
    }
  }

  // Click on route and open map with routes stops
  async clickOnRoute(route_id: string){
    const selectedRoute = this.routesArray.find((route: any) => route.route_id == route_id);
    // If the route has no start_date, the route hasnt started so display start route message
    if(selectedRoute.start_date == '' || selectedRoute.start_date == null){
      const message = await firstValueFrom(this.translate.get('startNewRoute'));
      if(confirm(message)){
        await this.setupStopsPage(route_id);
      }
    }
    // If we have a end_date, the route has already been complete
    else if (selectedRoute.end_date){
      const message = await firstValueFrom(this.translate.get('routeCompleted'));
      alert(message)
      return
    }
    // If the route is already started but not completed
    else{
      await this.setupStopsPage(route_id);
    }
  }

  private async setupStopsPage(route_id: string) {
    this.route_id = route_id;
    this.switchHTML();
    // Set the start date of the route
    this.routeService.startRoute(route_id);
    // Fill the route array with the result of the query
    this.routeService.stopsArray = await this.routeService.getStopsDetails(route_id);
    
    // TODO add loading screen on click
    await this.sleep(50);
    // Set CSS of labels
    this.setInitialStopLabelsCssClass();
    // Ask user if he want to use the browser or the map app of his mobile
    this.askUserForMapPreference();
  }

  setInitialStopLabelsCssClass() {
    const elements = document.querySelectorAll<HTMLOptionElement>('.stop-label');
    const stops = this.routeService.stopsArray;

    Array.from(elements).forEach((element, index) => {
      switch(stops[index].status){
        case 'I': // In progress
          break;
        case 'C': // Completed
          element.classList.add('stop-label-success', 'disabled');
          element.disabled = true
          break;
        case 'X': // Canceled
          element.classList.add('stop-label-error');
          element.disabled = true;
          element.setAttribute('ng-disabled', 'true');
          element.style.pointerEvents = 'none';
          break;
        case 'D': // Delayed
          break;
        case 'P': // Pending
          element.classList.add('stop-label-error');
          element.disabled = true;
          element.setAttribute('ng-disabled', 'true');
          element.style.pointerEvents = 'none';
          break;
        default:
          break;
      }
    });
  }

  clickOnStop(stopIndex: number){
    if(!stopIndex)
      return;
    // If our stop is completed, exit and do nothing
    const isComplete = this.routeService.stopsArray[stopIndex-1].status == 'C';
    if(isComplete){
      return;
    }
    // Stop the TTS if it's playing
    this.stopVoice();
    // Remove old user marker so we dont end up with multiple marker for the user
    if(this.userMarker != null){
      this.map.removeLayer(this.userMarker)
    }
    // Move the map to the user marker position
    //this.map.panTo(this.userMarker.getLatLng());

    this.selectedStopHandler(stopIndex);
  }

  /** The function that handle the function to call and the variables to set when we click on a stop */
  private selectedStopHandler(stopIndex: number) {
    // Get selected stop marker
    const marker = this.stopMarkers[stopIndex - 1];
    // Set stop detail
    this.selectedStopDetails = this.routeService.stopsArray[stopIndex - 1];
    // Reset the last read instruction, prevent TTS looping
    this.lastCurrentInstructionRead = '';
    // Close pickup modal, in case its open
    this.closePickupModal();
    // Create route
    this.createStopRouting(marker.getLatLng());
  }

  // Returns true if on mobile and false if on desktop
  isMobileDevice() {
    return /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
  }

  // Display confirmation message to use device map for device user, else just display it in the browser
  askUserForMapPreference() {
    this.displayInBrowserMap();
    /* if (this.isMobileDevice()) {
      const useMobileMap = confirm("Would you like to use your device's map app instead of the in-app map?");
      if (useMobileMap) {
        this.openMapInMobileApp();
      } else {
        this.displayInBrowserMap();
      }
    } else {
      this.displayInBrowserMap();
    } */
  }

  openMapInMobileApp() {
    try {
      const lat = 45.65059371772398;
      const lon = -73.84802491840571;
      const url = `geo:${lat},${lon}?q=${lat},${lon}`;
      window.location.href = url;
    } catch (error) {
      console.error("Failed to open map app:", error);
      // Optionally, fallback to in-app map or show an error message
      this.displayInBrowserMap();
    }
  }

  // display html and map
  displayInBrowserMap() {
    // Hide routes list
    this.displayRoutesList = false;
    // Initialize map
    this.mapInit();
  }

  switchHTML(){
    this.displayRoutesList =!this.displayRoutesList;
    this.displayStopsAndMap =!this.displayStopsAndMap;
  }

  focusOnUserPosition(){
    //this.isFocusOnUser = true;
    const user_position = this.userMarker?.getLatLng();
    this.map.panTo(user_position);
    this.map.zoomIn(0);
  }

  /**
   * This is use as a timer. Create a new promise that resolve after some time as passed
   * @param ms // Time in milliseconds
   * @returns
   */
  async sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  /**
   * Create a route from our user position to a single destination and display it on the map
   * @param destination
   */
  async createStopRouting(destination: L.LatLngExpression){
    // If we alrady have a route on map remove it
    if(this.routingControl){
      this.map.removeControl(this.routingControl);
    }
    if(this.userMarker != null){
      this.map.removeLayer(this.userMarker);
    }

    this.map.locate({ /* setView: true, */ watch: true/*, maxZoom: 16 */ });

    // Create a route from our user to our destination
    this.routingControl = L.Routing.control({
      waypoints: [
        L.latLng(this.userMarker.getLatLng()),
        L.latLng(destination)
      ],
      /* routeWhileDragging: true, */
    }).addTo(this.map);

    // Set our route instruction
    await this.setInstructions();
    // Read the first instruction to the user
    this.readCurrentInstruction();

    // Listener when the user is moving
    this.map.on('locationfound', (event) => {

      const userLocation = event.latlng;
       // Update user marker position
      if (this.routingControl) {
        // Check if we have move than a certain treshold before moving the user marker
        if (this.hasPositionChanged(userLocation)){
          // Change userMarker location to new one
          this.userMarker.setLatLng(userLocation);
          // Move marker
          this.routingControl.setWaypoints([userLocation, destination]);
          // Move map to user position
          this.map.panTo(this.userMarker.getLatLng());
        }
      }
    });

    // Listener when the map stop moving
    this.map.on('moveend', () => {
      //this.userMarker.setLatLng(this.map.getCenter());
      this.readCurrentInstruction()
      this.checkProximityToNextInstruction();
      this.checkIfAtDestination(destination);
    });
  };


  // DEPRECATED
  /**
  * Create a route from our user position to every stop in the selected route
  */
  /* createFullRoute(){
    // If we alrady have a route on map remove it
    if(this.routingControl){
      this.map.removeControl(this.routingControl);
    }
    // Array of our bin gps coord as a LatLng class for the routing
    let binsLatLngArray: L.LatLng[] = [];
    // Our first entry is the user position
    binsLatLngArray.push(L.latLng(this.userMarker.getLatLng()));
    // Then fill the array with the bins position
    this.stopMarkers.forEach((stop)=>{
      binsLatLngArray.push(L.latLng(stop.getLatLng()))
    })

    // Map the Array to L.LatLng for the waypoints
    const waypoints = binsLatLngArray.map(latLng => L.latLng(latLng));
    // Create the route with our waypoints and add it to the map
    this.routingControl = L.Routing.control({
      waypoints: waypoints,
      routeWhileDragging: true
    }).addTo(this.map);

    this.setInstructions();
  } */

  private async setInstructions(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // Listen for the 'routesfound' event to set the instructions
      const routesFoundHandler = (e: any) => {
        const routes = e.routes;
        this.instructions = routes[0].instructions;
        //this.routingControl.off('routesfound', routesFoundHandler);  // Clean up the event listener
        resolve();
      };

      // Listen for the 'routechanged' event in case the route changes
      const routeChangedHandler = (e: any) => {
        const route = e.route;
        this.instructions = route.instructions;
        //this.routingControl.off('routechanged', routeChangedHandler);  // Clean up the event listener
        resolve();
      };

      this.routingControl.on('routesfound', routesFoundHandler);
      this.routingControl.on('routechanged', routeChangedHandler);
    });
  }

  private readCurrentInstruction() {
    const currentInstruction = this.instructions[0] || null;
    if (currentInstruction != null) {
      // Check if our text is different to prevent repeating text reading
      const isNewText = this.lastCurrentInstructionRead != currentInstruction.text;
      if (isNewText) {
        const text = this.instructions[0].text;
        this.lastCurrentInstructionRead = text;
        this.readText(text);
      }
    }
  }

  // Check if we are close enough to our destination and enable the pick up button
  private checkIfAtDestination(destination: L.LatLngExpression){
    const userPosition = this.userMarker.getLatLng();
    const destinationDistance = L.latLng(destination);
    const distance = userPosition.distanceTo(destinationDistance); // Distance in meters
    const button = document.getElementById('pickupBinButton');
    // Check if the distance to the destination is within a threshold (e.g., 50 meters)
    if (distance < 50) {
      button?.removeAttribute('disabled');
    }
    else{
      button?.setAttribute('disabled', 'true');
    }
  }

  readNextInstruction(){
    const nextInstruction = this.instructions[1] || null;
    if (nextInstruction) {
      // Check if our text is different to prevent repeating text reading
      const isNewText = this.lastNextInstructionRead != nextInstruction.text
      if (isNewText) {
        const text = this.instructions[1].text;
        this.lastNextInstructionRead = text;
        this.readText(text);
      }
    }
  }

  checkProximityToNextInstruction() {
    const nextInstruction = this.instructions[1] || null;
    if (nextInstruction) {
      const currentInstruction = this.instructions[0];
      // We get the distance we are from the next waypoint by using our current instruction distance
      const nextInstructionDistance = currentInstruction.distance
      // Check if the user is within 200 meters of the next waypoint
      if (nextInstructionDistance < 200) {
        this.readNextInstruction();
      }
    }
  }

  /**
   * Function that use the Web Speech API to read a text on the device
   * @param text // text to read
   */
  private readText(text: string) {
    // Mute check
    if(this.isSoundMuted == false){
      const utterance = new SpeechSynthesisUtterance(text);
        utterance.lang = this.langToUseWithVoice;
        const selectedVoice = this.voices.find(voice => voice.lang === this.langToUseWithVoice);
        if (selectedVoice) {
          utterance.voice = selectedVoice; // Assign the selected voice
        } else {
          console.warn('Voice not found, using default voice.');
        }
        window.speechSynthesis.speak(utterance);
    }
  }

  /**Function that handles and logs geolocation error
   * @param error
   */
  private GeolocationPositionErrorHandling(error: GeolocationPositionError){
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        alert("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        alert("The request to get user location timed out.");
        break;
      default:
        alert("An unexpected error occurred.");
        break;
    }
  }

  stopVoice(){
    window.speechSynthesis.cancel();
  }

  volumeButton(){
    let speaker_icon = document.getElementById('speaker-icon')
    // null check
    if(speaker_icon){
      // if volume is unmuted
      if(speaker_icon.classList.contains('bi-volume-up')){
        // Change volume bootstrap icons
        speaker_icon.classList.remove('bi-volume-up');
        speaker_icon.classList.add('bi-volume-mute');
        this.isSoundMuted = true;
        // Cancel TTS
        window.speechSynthesis.cancel();
      }
      // If volume is muted
      else if(speaker_icon.classList.contains('bi-volume-mute')){
        // Change volume bootstrap icons
        speaker_icon.classList.add('bi-volume-up');
        speaker_icon.classList.remove('bi-volume-mute');
        this.isSoundMuted = false;
      }
    }
  }

  async returnToRoutes(){
    // Stop the TTS if it's playing
    this.stopVoice();
    const message: string = await firstValueFrom(this.translate.get('stillHaveRoutes'));
    let shouldBreak: boolean = false; // bool to check if we broke the loop
    // Loop through our stop array
    for (const stop of this.routeService.stopsArray) {
      // Do we have stops that are still in progress?
      if (stop.status === 'I') {
        shouldBreak = true;
        break;
      }
    }
    // If we broke the loop, it means we still have stops to complete.
    if(shouldBreak){
      // Display "still have stop to complete" message and on confirm change HTML
      if(confirm(message)) {
        // TODO add log, user quit his routes before completing it
        this.wakeLockService.releaseWakeLock();
        this.switchHTML();
        this.route_id = '';
        this.selectedStopDetails = undefined;
        this.selectedStopIndex = undefined;
      }
    }
    // Else we have completed the route
    else{
      this.wakeLockService.releaseWakeLock();
      this.switchHTML();
      this.route_id = '';
    }
  }

  ///////////// Pickup Modal /////////////
  openPickupModal(){
    // Null check
    if(this.pickupModal){
      // Is our modal already visible?
      if(this.pickupModal.style.visibility != 'visible'){
        // Set element visibility
        this.pickupModal.style.visibility = 'visible';
      }
    }
  }
  closePickupModal(){
    if(this.pickupModal){
      // Set element visibility
      this.pickupModal.style.visibility = 'hidden'
    }
  }
  async acceptButton() {
    // Null check
    if(this.selectedStopDetails){
      // Get our bin_id
      const stop_id = this.selectedStopDetails.route_stop_id;
      // Make the Service call and return true on success, or false
      const success = await this.routeService.updateRouteStopHTTP(stop_id, 'C');
      if(success){
        // Change the status of the stop in the stopsArray to Complete
        this.changeStatusOfStopInArray('C');
        // Put new css classes on success
        this.disableStopLabelOnSuccess();
        // Close modal
        this.closePickupModal();
        // Log the stop report
        this.routeService.logRouteStop(stop_id, this.driver_id, 'C')
        // Reset our selectedStop, do this last
        this.selectedStopDetails = undefined;
        this.checkRouteCompletion();
      }
      else{
        alert('Something went wrong while making the request, pls try again');
      }
    }
  }
  cantButton() {
    this.closePickupModal();
    this.openCantPickupModal();
  }
  //////////////////////////////////////////



  /////////// Cant pick up modal ///////////
  // Open or close the cant pickup modal
  openCantPickupModal(){
    // Null check
    if(this.cantPickupModal){
      // Hide or display element
      if(this.cantPickupModal.style.visibility == 'visible'){
        this.cantPickupModal.style.visibility = 'hidden';
      }else{
        this.cantPickupModal.style.visibility = 'visible';
      }
    }
  }
  // Close the cant pickup modal
  closeCantPickupButton() {
    // Null check
    if(this.cantPickupModal){
      // Hide element
      this.cantPickupModal.style.visibility = 'hidden';
      this.selectedError = '';
      this.cantPickupTextDetail = '';
    }
  }
  // Accept the cant pick up error
  async acceptCantPickupButton(){
    // Check if for the select validation
    this.checkValidationOfPickupError();
    if(this.validationService.pickupErrorSelectValid){
      // Null check
      if(this.selectedStopDetails){
        const stop_id = this.selectedStopDetails.route_stop_id;
        // Update stop status with 'P' for pending so the route job can reschedule it
        const success = await this.routeService.updateRouteStopHTTP(stop_id,'P')
        if(success){
          // Log the error
          this.routeService.logRouteStop(stop_id, this.driver_id, 'X', this.selectedError, this.cantPickupTextDetail);
          // Change the status of the stop in the Array to match the DB
          this.changeStatusOfStopInArray('P');
          // Close the cantPickupModal
          this.closeCantPickupButton();
          // Add error css
          this.addStopLabelErrorCss();
          this.selectedStopDetails = undefined
          this.checkRouteCompletion();
        }else{
          // TODO make proper error handling and log in fail attempt
          alert('Something went wrong while making the request, pls try again');
        }
      }
    }
  }
  //////////////////////////////////////////

  private addStopLabelErrorCss() {
    if(this.selectedStopDetails){
      const elements = document.querySelectorAll<HTMLOptionElement>('.stop-label');
      const index = this.selectedStopDetails.index;
      const element = elements[index - 1];
      element.classList.add('stop-label-error');
      element.disabled = true;
    }else{
      console.error('The selected stop is undefined.')
    }
  }

  /**
   *  Change the status of our stop in its array, the new status. MUST BE A SINGLE CHARACTER.
   *  Check the ../constants/route-stop-status.ts for all the possible status.
   * */
  private changeStatusOfStopInArray(newStatus:string) {
    // Loop through our stops Array
    for (const stop of this.routeService.stopsArray) {
      // When we find a matchin bin_id, change the status of the stop
      if (stop.bin_id == this.selectedStopDetails?.bin_id) {
        stop.status = newStatus;
        break;
      }
    }
  }

  private disableStopLabelOnSuccess(){
    if(this.selectedStopDetails){
      // Get all our stop labels
      const stop_labels = document.querySelectorAll('.stop-label');
      // Get our selected stop index
      const stop_index = this.selectedStopDetails?.index;
      // HTML element we want to add css to
      const elem = stop_labels[stop_index-1];
      elem.classList.add('stop-label-success', 'disabled');
    }else{
      console.log('selectedStop is undefined');
    }
  }

  checkValidationOfPickupError() {
    this.validationService.validatePickupErrorSelect(this.selectedError);
    // Define a mapping of error codes to corresponding error messages
    const errorMappings: Record<string, string> = {
      'PickupErrorSelectInvalid': 'pickupErrorSelectInvalid'
    };
    // Retrieve the first validation error from the array
    const validationError = this.validationService.validationErrorArray[0];
    // Check if the validation error code exists in the mapping
    if (errorMappings[validationError]) {
      this.systemMessageService.buttonBlocked = true;
      // If so, display a danger ribbon message with the corresponding key
      this.systemMessageService.selectRibbon('danger', errorMappings[validationError]);
    }
  }

  /**
   * Loop through our stopsArray to see if we still have stop in progress. If none are left display
   * completion and return to the routes page.
   */
  async checkRouteCompletion(){
    // Check if we still have stop in progress
    const routeComplete = !this.routeService.stopsArray.some(stop => stop.status === 'I');
    if(routeComplete){
      this.closePickupModal();
      this.routeService.endRoute(this.route_id);
      let route = this.routesArray.find((route:any) => route.route_id == this.route_id);
      // set end_date so the route tile become disabled when we return to the routes page
      const unixTimestamp = Math.floor(Date.now() / 1000);
      route.end_date = unixTimestamp;
      this.switchHTML();
      const message = await firstValueFrom(this.translate.get('routeCompleted'));
      alert(message);
    }
  }

  private setLanguageToUse(){
    const lang = this.translate.currentLang;
    switch(lang){
      case 'fr':
        this.langToUseWithVoice = 'fr-FR'
        break;
      case 'en':
        this.langToUseWithVoice = 'en-US'
        break;
      case 'es':
        this.langToUseWithVoice = 'es-ES'
        break;
    }
  }


  /**
   * Helper function to check if the position has changed significantly,
   * takes a new position and compares it with our last userMarker position,
   * returns true or false depending if we reach our distance treshold.
   * @param newPosition L.LatLng
   * @returns bool
   */
  private hasPositionChanged(newPosition: L.LatLng): boolean {
    // Check user position
    const distance = this.userMarker.getLatLng().distanceTo(newPosition);
    // Only consider the position as changed if the distance is greater than a threshold (e.g., 10 meters)
    return distance > 5;
  }


  public pickupBinButton(){
    if(this.isInProximityAndInProgress(this.selectedStopIndex)){
      this.openPickupModal();
    };
  }
}
